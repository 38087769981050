import React from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'

const Menu = () => {
  const
    { t, i18n } = useTranslation('common')

  return (
    <nav className="grid">
      <ul>
        <li><Link to={`/${i18n.language}`}>{t('home')}</Link></li>
        <li><Link to={`/${i18n.language}/about`}>{t('about')}</Link></li>
        <li><Link to={`/${i18n.language}/portfolio`}>{t('portfolio')}</Link></li>
        <li><Link to={`/${i18n.language}/contact`}>{t('contact')}</Link></li>
      </ul>
    </nav>
  )
}

export default Menu
