import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const { sanityContact } = useStaticQuery(
    graphql`
      query {
        sanityContact {
          fb
          ig
          copyright
          phone
        }
      }
    `
  )

  const
    { t, i18n } = useTranslation('common'),
    { fb, ig, copyright, phone } = sanityContact;

  return (
    <footer className="grid">
      <div className="social">
        <a href={fb} target="__blank">FB</a>
        <a href={ig} target="__blank">IG</a>
      </div>
      <div className="phone">
        <p><a href={`tel:${phone}`}>T: {phone}</a></p>
      </div>
      <div className="rights">
        <p>{copyright}</p>
      </div>
      <div className="privacy">
        <Link to={`/${i18n.language}/privacy-policy`}>{t("privacy")}</Link>
      </div>
      <div className="credits">
        <a href="https://latentestudio.com/?ref=gutierrezf" target="__blank">{t("credits")}</a>
      </div>
    </footer>
  )
}

export default Footer
