import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { window } from 'browser-monads'
import PropTypes from 'prop-types'
import Scroll from "./locomotiveScroll"

import Header from './header'
import Footer from './footer'
import FooterNoScroll from './footerNoScroll'
import Menu from './menu'
import Loading from './loading'

import icon from '../assets/images/favicon.svg'
import white from '../assets/images/favicon-white.svg'

import '../styles/custom.scss'
import '../styles/media.scss'
import "../styles/locomotive-scroll.css"

const Layout = ({ children, location, pageContext }) => {
  const [isClient, setClient] = useState(false);
  const [opacity, setOpacity] = useState(false);
  const dark = window.matchMedia('(prefers-color-scheme:dark)');

  useEffect(() => {
    setTimeout(() => {
      setOpacity(true);
    }, 1250);
    setTimeout(() => {
      setClient(true);
    }, 2250);
  }, []);

  if(pageContext.layout !== "scroll") {
    return (
      <>
        {isClient !== true && (
          <Loading opacity={opacity} />
        )}
        <Helmet>
          <link rel="icon" type="image/png" href={dark.matches ? white : icon} />
        </Helmet>
        <Header />
        <main>{children}</main>
        <Menu />
        <FooterNoScroll />
      </>
    )
  }

  if(pageContext.layout === "scroll") {
    return (
      <>
        {isClient !== true && (
          <Loading opacity={opacity} />
        )}
        <Helmet>
          <link rel="icon" type="image/png" href={dark.matches ? white : icon} />
        </Helmet>
        <Scroll callbacks={location} />
        <Header />
        <main data-scroll-container>{children}</main>
        <Menu />
        <Footer />
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
