import React, { useContext, useState } from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { AlternateLinksContext } from './wrapWithI18nProvider'

import MenuMobile from './menu-mobile'
import Logo from '../assets/svg/logo-tagline.svg'

const Header = () => {
  const
    alternateLinks = useContext(AlternateLinksContext),
    { t, i18n } = useTranslation('common'),
    [visible, setVisible] = useState(false);

  return (
    <>
      <MenuMobile visible={visible} setVisible={setVisible}/>
      <header className="grid">
        <Link className="logo-link" to={`/${i18n.language}`}>
          <Logo className="logo"/>
        </Link>
        <div className="language">
          {alternateLinks &&
            alternateLinks
              .filter(link => link.language !== i18n.language)
              .map((link, i) => [
                i > 0 && " | ",
                <Link
                  className="language-desktop"
                  key={i}
                  to={link.path}
                  style={{
                    textDecoration: `none`,
                  }}
                  hrefLang={link.language}
                >
                  {t(link.language)}
                </Link>,
              ])}
          <span className="menu-mobile" onClick={() => setVisible(true)} role="button" tabIndex={0}>MENU</span>
        </div>
      </header>
    </>
  )
}

export default Header
