import React, { useContext, useEffect } from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { AlternateLinksContext } from './wrapWithI18nProvider'

import Logo from '../assets/svg/icono.svg'
import Footer from './footer'

const MenuMobile = ({ visible, setVisible }) => {
  const
    { t, i18n } = useTranslation('common'),
    alternateLinks = useContext(AlternateLinksContext);

  useEffect(() => {
    if(visible) {
      document.querySelector('body').classList.add('overflow-none')
    } else {
      document.querySelector('body').classList.remove('overflow-none')
    }
  })

  return (
    <div className={!!visible ? "menu-mobile-container grid open" : "menu-mobile-container grid"}>
      <header className="grid">
        <Logo className="logo"/>
        <span className="menu-close" onClick={() => setVisible(false)} role="button" tabIndex={0}>CLOSE</span>
      </header>
      <ul className="grid">
        <li><Link onClick={() => setVisible(false)} to={`/${i18n.language}`}>{t('home')}</Link></li>
        <li><Link onClick={() => setVisible(false)} to={`/${i18n.language}/about`}>{t('about')}</Link></li>
        <li><Link onClick={() => setVisible(false)} to={`/${i18n.language}/portfolio`}>{t('portfolio')}</Link></li>
        <li><Link onClick={() => setVisible(false)} to={`/${i18n.language}/contact`}>{t('contact')}</Link></li>
        <li className="language">
        {alternateLinks &&
          alternateLinks
            .filter(link => link.language !== i18n.language)
            .map((link, i) => [
              i > 0 && " | ",
              <Link
                key={i}
                to={link.path}
                style={{
                  textDecoration: `none`,
                }}
                hrefLang={link.language}
                onClick={() => setVisible(false)}
              >
                {t(link.language)}
              </Link>,
            ])}
      </li>
      </ul>
    <Footer />
    </div>
  )
}

export default MenuMobile
