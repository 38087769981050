import React from 'react'
import LogoTagline from '../assets/svg/logo-tagline.svg'

const Loading = ({ opacity }) => (
  <div className={opacity === true ? "loading-screen hidden" : "loading-screen"}>
    <LogoTagline className="logo"/>
  </div>
)

export default Loading
